import React from 'react';
import SEO from '../../components/seo';
import styles from './job.module.css';

const ProductionDirector = () => {
  return (
    <>
      <SEO title="Production Director" />
      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Production Director</h1>

          <h3 className={styles.textSubHeader}>Výrobní ředitel CZ/SK</h3>
          <p className={styles.text}>
            Máš už v potravinářském průmyslu nebo v FMCG něco za sebou? Žiješ technologiemi? A hodně tě baví ty kolem výrobních procesů? Tak to jsi dost možná ten pravý/á kandidát/ka na pozici Výrobní ředite CZ/SK NutritionPro! 
          </p>

          <h3 className={styles.textSubHeader}>O NutritionPro</h3>
          <p className={styles.text}>
            Jsme nový, rychle rostoucí startup z oblasti FoodTech. Vyvíjíme softwarovou platformu, která zpracovává zákaznická data. Na jejichž základě pak generujeme individuální jídelní plány a předáváme je partnerským kuchyním k výrobě. 
          </p>
          <p className={styles.text}>
            Hotový produkt přivezeme klientovi až ke dveřím. A jsme úspěšní. Během dvouletého působení jsme překonali firmy, které jsou na trhu více než dekádu. 
          </p>

          <h3 className={styles.textSubHeader}>Co budeš mít na starosti?</h3>
          <p className={styles.text}>
            Jednu z nejdůležitějších části projektu – realizace výroby zboží ve stanovené kvalitě, v nákladech a v objemu.
          </p>
          <p className={styles.text}>
            Kontrola celého výrobního procesu u partnerských kuchyní. Budou za tebou tisíce spokojených klientů a renomé té nejlepší krabičkové diety v Čechách a na Slovensku.
          </p>

          <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Vedení výrobního oddělení, včetně supply chain a oddělení kvality.
            </li>
            <li className={styles.newList}>
              Realizace prodávaného zboží v očekávané kvalitě, objemu a nákladech.
            </li>
            <li className={styles.newList}>
              Vyhledávání nových a udržování současných vztahů s dodavateli – smluvní kuchyně, logistická centra a služby rozvozu.
            </li>
            <li className={styles.newList}>
              Komunikace a sestavování prodejního plánu s komerčním oddělením. 
            </li>
            <li className={styles.newList}>
              Podrobná analýza a kontrola nákladů u dodavatelů. 
            </li>
            <li className={styles.newList}>
              Odpovědnost za budgety a ekonomické výsledky oddělení
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co bys měl ideálně umět?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Schopnost vyjednávat a udržovat dobré vztahy s dodavateli
            </li>
            <li className={styles.newList}>
              Zkušenosti z výrobního provozu v gastronomii nebo FMCG
            </li>
            <li className={styles.newList}>
              Orientace na finanční a datové analýzy, znalost základních ekonomických postupu
            </li>
            <li className={styles.newList}>
              Vyznáš se v unit ekonomice, umíš postavit podrobný nákladový plán pro zadané zboží dle možností dodavatelů.
            </li>
            <li className={styles.newList}>
              Schopnost užívat nové technologie
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>A co za to dostaneš?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Motivující finanční ohodnocení (80.000-140.000 Kč), měsíční bonusy, opční plán (podíl firmy)
            </li>
            <li className={styles.newList}>
              Full-time uplatnění
            </li>
            <li className={styles.newList}>
              Plně hrazené vzdělávací kurzy
            </li>
            <li className={styles.newList}>
              Multisport kartu a fitko přímo v kanceláři zdarma
            </li>
          </ul>

          <p className={styles.text}>
            A když už jsme u místa tvého působiště, sídlíme v Karlíně. Takže tě čeká super prostředí pro networking, žádný dress code a pražská panoramata z naší úžasné terasy.
          </p>

          <h3 className={styles.textSubHeader}>
            Tak už máme tvoje CVčko? Tým NutritionPro se těší, až tě přivítá!
          </h3>
          <h3 className={styles.textSubHeader}>Kontakty:</h3>
          <a className={styles.jobsEmail} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
        </div>
      </div>
    </>
  )
}

export default ProductionDirector
